import { Card } from "antd";

const EditingComponent = ()=>{
    return(
        <>
            <Card>
                <h3> Güncellleniyor.... </h3>
                <p> Lütfen daha sonra tekrar deneyiniz. </p>
            </Card>
        </>
    );
};



export default EditingComponent;