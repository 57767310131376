import { CenterComponents } from "centerComponent";
import tmHome from "templates/home";
import { getData } from "utils/fetchData";
const templates = require("templates/home").default;

const Home = ({ cards, fetchData }) => {
  return (
    <>
      <CenterComponents cards={fetchData.data} />
    </>
  );
};
export async function getStaticProps(context) {
  const result = await getData("/Site/GetHome", false);
  return {
    props: {
      fetchData: result,
      // cards: tmHome.data,
    },
    revalidate: 2,
  };
}
export default Home;
