import dynamic from "next/dynamic";
import { capitalizeFirstLetter } from "utils/services";
import EditingComponent from "components/container/editingIsComponent";

// All Template
const DetailsPage = {
  // Home Pages
  ViranSehirHaberlerDetay: dynamic(() =>
    import("./components/site/haber-detay/haber_detay")
  ),
  TumHaberlerDetay: dynamic(() =>
    import("./components/site/tum-haberler-liste/tum-haberler-list")
  ),
  Foto_Detay: dynamic(() => import("./components/site/fotoList/fotoList")),
  TumVideolar: dynamic(() => import("./components/site/videoList/videoList")),
  VideoDetay: dynamic(() =>
    import("./components/site/video-detay/video-detay")
  ),
};

const anaSayfa = {
  ViranSehirHaberler: dynamic(
    () => import("./components/site/haberler/haberler"),
    {
      loading: <>ViranSehirHaberler loading</>,
    }
  ),
  BaskanHakkinda: dynamic(() => import("./components/site/hakkimda/hakkimda"), {
    loading: <>BaskanHakkinda loading</>,
  }),
  Galeri: dynamic(() => import("./components/site/galeri/galeri"), {
    loading: <>Galeri loading</>,
  }),
  Iletisim: dynamic(() => import("./components/site/ulasim/ulasim"), {
    loading: <>Iletisim loading</>,
  }),
};

export const Sidebars = {
  Sidebar1: dynamic(() => import("./components/site/sidebar1/sidebar")),
  Sidebar2: dynamic(() => import("./components/site/sidebar2/sidebar")),
};

// Filter Components
export const GetSideBars = ({ sidebarName, data }) => {
  var C =
    Sidebars[capitalizeFirstLetter(sidebarName)] != undefined
      ? Sidebars[capitalizeFirstLetter(sidebarName)]
      : () => "";
  return <C data={data} id={data?.categoryId} />;
};

export const CenterComponents = ({ cards }) => {
  return (
    <main>
      {cards.map((item, index) => {
        var C =
          anaSayfa[capitalizeFirstLetter(item.name)] != undefined
            ? anaSayfa[capitalizeFirstLetter(item.name)]
            : () => "";
        return <C key={index} data={item} />;
      })}
    </main>
  );
};

export const CenterComponentsContents = ({ cards, isCategory }) => {
  return (
    <>
      {!cards?.subCategori?.sections ? (
        <>
          <EditingComponent />
        </>
      ) : (
        cards?.subCategori.sections?.map((item) => {
          if (
            isCategory == true &&
            item.template.location == "CategoryDetails"
          ) {
            // get kategori tempalte
            var C =
              DetailsPage[capitalizeFirstLetter(item.template.name)] !=
              undefined ? (
                DetailsPage[capitalizeFirstLetter(item.template.name)]
              ) : (
                <EditingComponent />
              );
            return <C data={cards} id={cards?.categoryId} />;
          } else if (
            isCategory == false &&
            item.template.location == "ContentDetails"
          ) {
            // get contents template
            var C =
              DetailsPage[capitalizeFirstLetter(item.template.name)] !=
              undefined ? (
                DetailsPage[capitalizeFirstLetter(item.template.name)]
              ) : (
                <EditingComponent />
              );
            return <C data={cards} id={cards?.categoryId} />;
          }
        })
      )}
    </>
  );
};
